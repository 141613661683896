.navBar {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.5rem 1.05rem;
}

.navBar .logo {
  cursor: pointer;
  display: block;
}

.navBar .logo img {
  height: 25px;
}

.navBar .links {
  margin-left: auto;
  font-size: 0.7rem;
}

.navBar .links a {
  margin-left: 16px;
  text-decoration: none;
  color: #131936;
  display: inline-flex;
}

.navBar .links a:hover {
  color: #5c70cd;
}

.button {
  background: #5c70cd;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.button:hover {
  background-color: #3857dd;
}

.navBar-btn {
  margin-left: 10px;
}

@media screen and (min-width: 600px) {
  .navBar {
    padding: 0.7rem 2.5rem;
  }

  .navBar .links {
    font-size: 1rem;
    padding: 5px 0;
  }
}

@media screen and (max-width: 991px) {
  .links {
    margin-left: auto;
  }
}
