.homepage {
  height: 100%;
  margin-top: 45px;
}

.l-heading {
  font-size: 41px;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  max-width: 925px;
}

.m-heading {
  margin-bottom: 1rem;
  max-width: 825px;
  padding-bottom: 1rem;
  font-size: 20px;
  line-height: 32px;
  color: #131936;
  font-weight: 600;
}

.homepage-img {
  max-width: 100%;
}

input[type='text'],
input[type='file'],
input[type='number'],
input[type='tel'] {
  background-color: #f1f2f5;
  border: 1px solid #e7e7ed;
  border-radius: 4px;
  padding: 10px;
  color: #6b6c7e;
}

input::file-selector-button {
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  background: #5c70cd;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0em;
  border: none;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.button {
  background: #5c70cd;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.button:hover {
  background-color: #3857dd;
}
/*# sourceMappingURL=Homepage.css.map */
