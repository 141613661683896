@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  letter-spacing: -0.4px;
}

.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.important-field:after {
  content: '*';
  color: #5c70cd;
}

@media print {
  #ms-container {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  p {
    page-break-inside: avoid;
  }

  @page {
    size: A4;
    margin: 0;
  }

  .print-button {
    display: none;
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

#ms-container {
  width: 100%;
  height: 100vh;
  background: transparent;
}

.ms-content {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 10px solid #2ea4f2;
  border-radius: 100%;
  cursor: pointer;
}

.ms-content-inside {
  width: 100%;
  height: 100%;
  position: relative;
}

.ms-line-down-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotate(0deg);
  transition: transform 0s ease;
}
#ms-download {
  display: none;
}
#ms-download:checked ~ .ms-line-down-container {
  transform: rotate(-360deg);
  transition: transform 1.5s ease 1.25s;
}

.ms-line-down {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 125px;
  background: #2ea4f2;
  border-radius: 50px;
  transition: height 0.5s ease;
}

#ms-download:checked ~ .ms-line-down-container .ms-line-down {
  height: 10px;
  top: -20px;
  animation: ms-bounce 0.5s forwards 0.55s;
}
#ms-download:checked ~ .ms-line-down-container .ms-line-down:before {
  content: '';
  position: absolute;
  top: 0;
  left: -94px;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 200px;
  border: 10px solid transparent;
  border-radius: 100%;
  animation: ms-border-fill 0.5s forwards 3s;
}

.ms-line-point {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 58px;
  height: 130px;
  transition: height 0.15s ease 0.45s;
}

#ms-download:checked ~ .ms-line-point {
  height: 60px;
}

#ms-download:checked ~ .ms-line-point:before {
  transform: rotate(90deg);
  right: -10px;
  animation: ms-check-right 0.25s forwards 3s;
}

#ms-download:checked ~ .ms-line-point:after {
  transform: rotate(-90deg);
  left: -10px;
  animation: ms-check-left 0.25s forwards 3s;
}

.ms-line-point:before,
.ms-line-point:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 75px;
  bottom: -10px;
  background: #2ea4f2;
  border-radius: 50px;
  transition: transform 0.15s ease 0.47s, left 0.15s ease 0.47s,
    right 0.15s ease 0.47s;
}

.ms-line-point:before {
  right: 0px;
  transform: rotate(45deg);
}

.ms-line-point:after {
  left: 0px;
  transform: rotate(-45deg);
}

@keyframes ms-bounce {
  0% {
    top: -20px;
  }
  25% {
    top: -250px;
  }
  50% {
    top: -275px;
  }
  100% {
    top: -190px;
  }
}

@keyframes ms-check-left {
  0% {
    transform: rotate(-90deg);
    left: -10px;
    height: 75px;
  }
  100% {
    transform: rotate(-45deg);
    left: 0px;
    height: 45px;
  }
}

@keyframes ms-check-right {
  0% {
    transform: rotate(90deg);
    right: -10px;
    height: 75px;
    bottom: -10px;
  }
  100% {
    transform: rotate(45deg);
    right: 10px;
    height: 80px;
    bottom: -15px;
  }
}

@keyframes ms-border-fill {
  0% {
    border: 10px solid transparent;
  }
  100% {
    border: 10px solid #2ea4f2;
  }
}

.print-source {
  display: none;
}

@media print {
  .print-source {
    display: block;
    margin: 0;
  }
}

/* --------------- */
.error-text {
  color: red;
  font-size: 12px;
  padding: 5px;
}

input,
select,
textarea {
  font-size: 16px;
}
