.navBar {
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    cursor: pointer;
    display: block;
    margin-left: 5px;
  }

  .links {
    padding: 10px;
    margin-left: auto;
    a {
      @include links;
    }
  }
}

.button {
  @include buttons;
}

@media screen and (max-width: 991px) {
  .links {
    padding: 5px;
    margin-left: auto;
    a {
      @include links;
    }
  }
}
